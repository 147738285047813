import React, { useEffect, useState, useContext } from "react"
import { Link } from "gatsby"

import styles from "./dashboard.module.scss"

import Layout from "../components/layout/layout"
import guardianCounselApi from "../apis/guardianCounselApi"
import DocumentList from "../components/documents/documentList"

import { AuthContext } from "../context/AuthContext"

const LoadingPlaceholder = () => {
  return (
    <>
      <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
    </>
  )
}

const Dashboard = () => {
  const {
    state: { authData },
  } = useContext(AuthContext)

  const [loadingStatus, setLoadingStatus] = useState("loading")
  const isLoading = loadingStatus === "loading"
  const isLoaded = loadingStatus === "finished"
  const isErrored = loadingStatus === "error"

  const [docs, setDocs] = useState({ uploads: [], downloads: [] })

  useEffect(() => {
    const fetchOneDriveItems = async () => {
      try {
        var response = await guardianCounselApi.get("/documents/list")

        setDocs(response.data)
        setLoadingStatus("finished")
      } catch (err) {
        setLoadingStatus("error")
      }
    }

    fetchOneDriveItems()
  }, [])

  return (
    <>
      <Layout>
        <br />

        <h1 style={{ textAlign: "center" }}>Dashboard</h1>
        <div
          style={{
            marginBottom: "25px",
            textAlign: "center",
          }}
        >
          Welcome, {authData != null && authData.name}!
        </div>

        <div className="row" style={{ flexGrow: 1 }}>
          <div
            className="col"
            style={{
              border: "1px solid white",
              padding: "15px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h4>Documents From Guardian Counsel</h4>
              {docs.downloads.length > 0 && <p>This is where you can download documents from us.</p>}
              {docs.downloads.length === 0 && <p>This is where you'll see documents from us.</p>}
              <div style={{ visibility: "hidden", marginBottom: "25px" }}>
                <div className="btn btn-success">
                  <i className="fa fa-plus" style={{ marginRight: "10px" }} />
                  <span>spacer button</span>
                </div>
              </div>

              {isLoading && <LoadingPlaceholder />}
              {isErrored && <>Something went wrong loading your documents! Please try again later or contact us.</>}
            </div>
            {isLoaded && (
              <>
                {docs.downloads.length === 0 && (
                  <p>
                    We have not made any of your files available for download yet; Please check back soon. If you
                    believe this to be in error, please let us know at law@guardiancounsel.com or call (952) 217-3177.
                  </p>
                )}
                {docs.downloads.length > 0 && <DocumentList items={docs.downloads} />}
              </>
            )}
          </div>
          <div className="col" style={{ border: "1px solid white", padding: "15px" }}>
            <div style={{ textAlign: "center" }}>
              <h4>Documents Sent To Guardian Counsel</h4>
              <p>This is where you can upload documents that you want to send to us.</p>
              <div style={{ marginBottom: "25px" }}>
                <Link className="btn btn-success" to="/upload">
                  <i className="fa fa-plus" style={{ marginRight: "10px" }} />
                  <span>Upload Files</span>
                </Link>
              </div>
              {isLoading && <LoadingPlaceholder />}
              {isErrored && <>Something went wrong loading your documents! Please try again later or contact us.</>}
            </div>
            {isLoaded && (
              <>
                {docs.uploads.length === 0 && <p>You haven't shared any files with us yet.</p>}
                {docs.uploads.length > 0 && <DocumentList items={docs.uploads} />}
              </>
            )}
          </div>
        </div>
        <br />
      </Layout>
    </>
  )
}

export default Dashboard

import React, { useState } from "react"
import guardianCounselApi from "../../apis/guardianCounselApi"

export interface IDocument {
  id: string
  name: string
  size: number
  downloadUrl: string
}

const DocumentListItem = ({ item, idx }: { item: IDocument; idx: number }) => {
  const [showFullTitle, setShowFullTitle] = useState(false)

  const downloadClicked = async () => {
    await guardianCounselApi.post("/documents/download", { fileName: item.name })
  }

  return (
    <>
      <div
        style={{ padding: "20px 0px", borderTop: "1px solid white" }}
        onMouseEnter={() => setShowFullTitle(true)}
        onMouseLeave={() => setShowFullTitle(false)}
      >
        <div
          style={{
            display: "flex",
            whiteSpace: "normal",
          }}
        >
          <div style={{ paddingRight: "10px" }}>{idx}. </div>
          <div style={{ flexGrow: 1 }}>
            {showFullTitle && <>{item.name}</>}
            {!showFullTitle && (
              <>
                {item.name.substr(0, 30)}
                {item.name.length > 30 ? "..." : ""}
              </>
            )}
          </div>
          <div style={{ flexShrink: 0 }}>
            <a href={item.downloadUrl} className="btn btn-info" onClick={downloadClicked}>
              <i className="fa fa-download" style={{ paddingRight: "10px" }}></i>
              Download
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

const DocumentList = ({ items }: { items: IDocument[] }) => {
  return (
    <>
      {items.map((i, idx) => (
        <DocumentListItem key={i.id} idx={idx + 1} item={i} />
      ))}
    </>
  )
}

export default DocumentList
